<template>
  <div>
    <div v-for="(item, index) in name_urls" :key="index" style="transform: rotate(-90deg);width: 45%;">
      <img :src="item.nameUrl" alt="">
    </div>
  </div>
</template>

<script>
import {Encrypt} from "@/utils/secret";
import {ElMessage} from "element-plus";

export default {
  name: "",
  data() {
    return {
      name_urls: []
    }
  },
  created() {
    this.getNameUrl()
  },
  methods: {
    getNameUrl(){
      this.axios.get('/secrecySignature/getNameUrl', (response) => {
       this.name_urls = response.obj
      })
    }
  }
}
</script>

<style scoped>

</style>